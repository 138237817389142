import axios     from "axios";
import {Sentry}  from "Figured/Assets/Modules/sentry";
import * as globals from "./globals.js"
import { skipSentryError, markSentryError } from "Figured/Assets/Modules/sentryErrorSkiping";
import * as routes from "./routes.js";

/**
 * This will simulate a POST request being submitted through a <form> object.
 *
 * @param {string} url
 * @param {object} params
 */
export function formPost(url, params)
{
    const form = document.createElement("form");
    form.method = "POST";
    form.action = url;

    const addField = (key, value) => {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = value;

        form.appendChild(hiddenField);
    };

    /**
     * Adds the CSRF token
     */
    addField("_token", globals.getCSRF());

    for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            addField(key, params[key]);
        }
    }

    document.body.appendChild(form);
    form.submit();
}

/**
 * This header helps the server to understand that this is an asynchronous request.
 */
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Capture errors
 * - 401 errors indicate user has been logged out, redirect to the login page
 * - 500 errors indicate server error, send to sentry
 *
 * This is useful because the error would otherwise be a lot harder to debug.
 */
axios.interceptors.response.use((response) => response,
    (error) => {
        markSentryError(error);

        // Handle 401 Unauthorized responses
        if (error.response && error.response.status === 401) {
            // Check if the response contains a redirect URL
            if (error.response.data && error.response.data.redirect) {
                window.location = error.response.data.redirect;
                return Promise.reject(error); // Still reject so calling code knows there was an error
            } else {
                // If no specific redirect is provided, redirect to login page with current URL as redirect parameter
                // const currentPath = encodeURIComponent(window.location.pathname + window.location.search);
                const currentPath = window.location.pathname + window.location.search;
                window.location = routes.get("login.page", {}, {redirect: currentPath});
                return Promise.reject(error);
            }
        }

        if (!skipSentryError(error)) {
            Sentry.captureException(error);
        }

        return Promise.reject(error);
    },
);

/**
 * Capture all request errors so that we can send them to sentry, which
 * is useful because the error would otherwise be a lot harder to debug.
 *
 * This also sets the default CSRF token header so that we don't have to
 * do this manually every time, for every request.
 */
axios.interceptors.request.use((config) => {
    config["X-CSRF-TOKEN"] = globals.getCSRF();
    return config;
},
(error) => {
    markSentryError(error);
    if (!skipSentryError(error)) {
        Sentry.captureException(error);
    }

    return Promise.reject(error);
}
);

/**
 * @type {AxiosStatic}
 */
export const http = axios;

/**
 * Export the axios instance directly, after defaults have been set.
 */
export default axios;
